/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

/**
 * import local components
 */
import Header from "./Header"
import Footer from "./Footer"

/**
 * Layout
 */
const Layout = ({ children }) => {
  return (
    <div className="layout">
      <Header />
      <main className="layout-main">
        <div className="container">{children}</div>
      </main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  navTheme: PropTypes.string,
  footerTheme: PropTypes.string,
}

export default Layout
