import React from "react"
import PropTypes from "prop-types"
import logoFooter from "../../assets/img/logo-footer.png"
import { Link } from "gatsby"
import beian from "../../assets/img/beian.png"
/**
 * import local components
 */

/**
 * Footer
 */
const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="triangle-up"></div>
          <div className="col-lg-4">
            <div className="hr_20"></div>
            <div className="footer-logo pull-left">
              <img src={logoFooter} />
            </div>
            <div className="pull-right" style={{ marginRight: 60 }}>
              <h5>天津道宽明科技有限公司</h5>
              <h6>
                <a
                  target="_blank"
                  href="https://beian.miit.gov.cn/"
                  style={{
                    display: "inline-block",
                    textDecoration: "none",
                    height: 20,
                    lineHeight: 20,
                  }}
                >
                  <img src={beian} style={{ float: "left" }} />
                  <p
                    style={{
                      float: "left",
                      height: 20,
                      lineHeight: "20px",
                      margin: "0px 0px 0px 5px",
                      color: "#fff",
                    }}
                  >
                    津ICP备16008270号-1
                  </p>
                </a>
              </h6>
            </div>
          </div>
          <div className="col-lg-8  text-right">
            <div className="hr_40"></div>
            <ul>
              <li>
                <Link to="/" activeClassName="current-menu-item">
                  首页
                </Link>
              </li>
              <li>
                <Link to="/about" activeClassName="current-menu-item">
                  关于我们
                </Link>
              </li>
              <li>
                <Link to="/products" activeClassName="current-menu-item">
                  公司产品
                </Link>
              </li>
              <li>
                <Link to="/gallery" activeClassName="current-menu-item">
                  硬件设备
                </Link>
              </li>
              <li>
                <Link to="/news" activeClassName="current-menu-item">
                  新闻中心
                </Link>
              </li>
              <li>
                <Link to="/contact" activeClassName="current-menu-item">
                  联系我们
                </Link>
              </li>
              <div className="clearfix"></div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

Footer.propTypes = {
  siteJson: PropTypes.object,
  theme: PropTypes.string,
}

Footer.defaultProps = {
  siteJson: {
    // title: "",
    // publicPhoneNumber: "",
  },
  theme: "", // ""|info
}

export default Footer
