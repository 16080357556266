import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import map from "lodash/map"
import get from "lodash/get"

const Category = ({ allCategories }) => {
  return (
    <div className="sidebar">
      <div className="border-top">
        <div className="border-bot">
          <div className="border-left">
            <div className="border-right">
              <div className="corner-top-left">
                <div className="corner-top-right">
                  <div className="corner-bot-left">
                    <div className="corner-bot-right">
                      <div className="inner">
                        <div className="wrapper">
                          <span>产品目录</span>
                          <ul className="list-1">
                            {map(allCategories, (cat, index) => (
                              <li key={index}>
                                <Link
                                  to={`/products/category/${cat.slug}/`}
                                  key={index}
                                >
                                  {get(cat, "title.title", "")}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Category.propTypes = {
  allCategories: PropTypes.array,
}

export default Category
