// @ts-nocheck
import React from "react"
import PropTypes from "prop-types"
// @ts-ignore
import LogoHeader from "../../assets/img/logo-cn.png"
import { Link } from "gatsby"

/**
 * import local components
 */

/**
 * Header
 */
const Header = () => {
  return (
    <div className="header">
      <div className="top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 text-left">
              <marquee>道宽明科技有限公司欢迎您</marquee>
            </div>
            {/* <div className="col-lg-3 col-lg-offset-6 text-right">
                            	<a href="http://www.docmanchem.com">中文</a>&nbsp;|&nbsp;<a href="http://www.docmanchem.com/en">English</a>
                            </div> */}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-lg-12">
          <div className="triangle-down"></div>
          <div className="hr_20"></div>
          <div className="col-lg-3">
            <div className="logo" id="header_logo">
              <a href="/">
                <img src={LogoHeader} />
              </a>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="hr_20"></div>
            <div className="col-lg-8">
              <form action="<?php bloginfo('url'); ?>/" method="GET">
                <div className="input-group">
                  <input
                    name="s"
                    id="s"
                    type="text"
                    className="form-control"
                    placeholder="Name, CAT#, CAS#"
                  />
                  <span className="input-group-btn">
                    <button className="btn btn-default" type="submit">
                      <i className="icon-search"></i>&nbsp;
                    </button>
                  </span>
                </div>
              </form>
            </div>
            <div className="phone text-right">
              <a href="">
                {" "}
                <i className="icon-phone"></i>
                <span>15122949573</span>
              </a>
            </div>
            <div className="hr_40"></div>
            <div className="hr_15"></div>
            <div className="menu nav">
              <ul>
                <li>
                  <Link to="/" activeClassName="current-menu-item">
                    首页
                  </Link>
                </li>
                <li>
                  <Link to="/about" activeClassName="current-menu-item">
                    关于我们
                  </Link>
                </li>
                <li>
                  <Link to="/products" activeClassName="current-menu-item">
                    公司产品
                  </Link>
                </li>
                <li>
                  <Link to="/gallery" activeClassName="current-menu-item">
                    硬件设备
                  </Link>
                </li>
                <li>
                  <Link to="/news" activeClassName="current-menu-item">
                    新闻中心
                  </Link>
                </li>
                <li>
                  <Link to="/contact" activeClassName="current-menu-item">
                    联系我们
                  </Link>
                </li>
                <div className="clearfix"></div>
              </ul>
            </div>
          </div>
          <div className="hr_10"></div>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  siteJson: PropTypes.object,
  theme: PropTypes.string,
}

Header.defaultProps = {
  siteJson: {
    // title: "",
    // publicPhoneNumber: "",
  },
  theme: "", // ""|purple
}

export default Header
