import React from "react"
import get from "lodash/get"
import { useStaticQuery, graphql, Link } from "gatsby"
import map from "lodash/map"

const NewsSide = () => {
  const data = useStaticQuery(graphql`
    query {
      newsJson: allContentfulNewsPost(
        limit: 2
      ) {
        totalCount
        edges {
          node {
            title
            slug
            createdAt(formatString: "MMMM Do, YYYY")
            thumbnail {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)
  const news = get(data, "newsJson.edges", [])
  return (
    <div className="news-list">
      <dl>
        <dt>
          <h3>
            <a href="">行业动态</a>
          </h3>
        </dt>
        {map(news, (item, index) => (
          <dd key={index}>
            <h5 className="tit">
              <Link to={`/news/${item.node.slug}`}>{item.node.title}</Link>
            </h5>
            <span className="small">{item.node.createdAt}</span>
          </dd>
        ))}
      </dl>
    </div>
  )
}

export default NewsSide
