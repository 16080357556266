import React from "react"
import PropTypes from "prop-types"
import Layout from "./index"
import Category from "../../components/category"
import News from "../../components/news"
import Cantact from "../../components/contact"
import SEO from "../../components/seo"

const Post = ({ location, allCategories,  children }) => (
  <Layout>
    <SEO title={location} />
    <div className="hr_20"></div>
    <div className="page-con">
      <div className="col-lg-12 bg-white">
        <div className="hr_25"></div>
        <div className="col-lg-3">
          {allCategories && <Category allCategories={allCategories} />}
          <News />
          <Cantact />
        </div>
        <div className="col-lg-9">{children}</div>
        <div className="hr_25"></div>
      </div>
    </div>
  </Layout>
)

Post.propTypes = {
  children: PropTypes.oneOfType(
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ),
  location: PropTypes.string,
  allCategories: PropTypes.array
}

Post.defaultProps = {
  children: "",
  location: "Home",
}

export default Post
