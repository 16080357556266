import React from "react"
import get from "lodash/get"
import { useStaticQuery, graphql } from "gatsby"

const ContactSide = () => {
  const data = useStaticQuery(graphql`
    query {
      contactJson: contentfulPage(slug: { eq: "contact" }) {
        modules {
          ... on ContentfulPageCopy {
            id
            copy {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `)

  const contents = get(
    data,
    "contactJson.modules[0].copy.childMarkdownRemark.html",
    ""
  )

  return (
    <div className="news-list">
      <dl>
        <dt>
          <h3>
            <a href="">联系我们</a>
          </h3>
        </dt>
        <dd>
          <div
            dangerouslySetInnerHTML={{
              __html: contents,
            }}
          />
        </dd>
      </dl>
    </div>
  )
}

export default ContactSide
